<template>
  <div style="textAlign: center">
      <div class="err-large">404</div>
      <div class="err-messge">您访问的页面不存在</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.err-large {
  font-family: fantasy;
  color: @joy-orange-color;
  font-size: 200px;
  font-weight: bolder;
  opacity: 0.3;
}

.err-messge {
  padding-bottom: 50px;
}
</style>
